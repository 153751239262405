import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Modal from "react-modal"
import Hero from "../components/members-zone/hero.js"

import DownloadImg from "../../static/assets/image/Data-Suite-Download-button-120x115.jpg"
import setupImg1 from "../../static/assets/image/setupImg1.png"
import setupImg2 from "../../static/assets/image/setupImg2.png"
import setupImg3 from "../../static/assets/image/setupImg3.png"
import setupImg4 from "../../static/assets/image/setupImg4.png"
import setupImg5 from "../../static/assets/image/setupImg5.png"
import setupImg6 from "../../static/assets/image/setupImg6.png"
import setupImg7 from "../../static/assets/image/setupImg7.png"
import setupImg8 from "../../static/assets/image/setupImg8.png"
import updateImg1 from "../../static/assets/image/setupImg1.png"
import updateImg2 from "../../static/assets/image/setupImg2.png"
import updateImg3 from "../../static/assets/image/setupImg3.png"
import updateImg4 from "../../static/assets/image/setupImg4.png"
import updateImg5 from "../../static/assets/image/setupImg5.png"

import COMMBtalksImg from "../images/commb-talks-header.png"
import autoCategories from "../images/autoCategories.png"
import CommbTalksImg from "../images/commb-talks.jpg"
import marketingSalesResearch from "../images/MarketingSalesResearchThumbnail.png"
import IndustryEventsThumbnail from "../images/IndustryEventsThumbnail.png"
import ArticleThumbnail from "../images/ArticlesThumbnail.png"

const MembersZone = ({ data }) => {
    function categoryReportsRedirect() {
        window.open('https://downloads.ctfassets.net/1l6si2vnlb2k/WqoelrYp1gI5WJUtKWo7l/272d488b6b41cdfaf0dfa0a587e9436d/Automotive_Category_Report_-_EN_-_Final.pdf', '_blank');
    }

    function categoryReportsRedirectTwo() {
        window.open('https://assets.ctfassets.net/1l6si2vnlb2k/6GcZsAEfMzC35eLqfvmwXD/b7109ce404e40906311ec298f8c39b1a/Travel_Category_Report_-_EN.pdf', '_blank');
    }

    function eventsRedirect() {
         window.location.href = "/events";
    }

    function talksRedirect() {
         window.location.href = "/talks";
    }

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

const [modalIsOpenEighteen, setIsOpenEighteen] = useState(false)
function openModalEighteen() {
  setIsOpenEighteen(true)
}
function closeModalEighteen() {
  setIsOpenEighteen(false)
} 
    
const [modalIsOpenNineteen, setIsOpenNineteen] = useState(false)
  function openModalNineteen() {
    setIsOpenNineteen(true)
  }
  function closeModalNineteen() {
    setIsOpenNineteen(false)
} 

const [modalIsOpenTwenty, setIsOpenTwenty] = useState(false)
  function openModalTwenty() {
    setIsOpenTwenty(true)
  }
  function closeModalTwenty() {
    setIsOpenTwenty(false)
} 


    
  Modal.setAppElement("#___gatsby")

    return (
        <div className="membersZone">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                 <div className="membersZoneCardThumbnailsWrapper">
                    <h2>EXCLUSIVES</h2>
                    <div className="membersZoneCardThumbnails">
                        <div onClick={talksRedirect}>
                            <img src={CommbTalksImg} />
                            <h3> COMMB Events</h3>
                        </div>
                        <div onClick={openModalThirteen}>
                            <img src={COMMBtalksImg} />
                            <h3> COMMB Talks (Members-Only)</h3>
                        </div>
                    </div>
                    <h2>MARKETING & SALES RESOURCES</h2>
                        <div className="membersZoneCardThumbnails">
                            <div onClick={openModalEleven}>
                                <img src={marketingSalesResearch} />    
                            <h3> Marketing & Sales Research Presentations</h3>
                            </div>
                            <div onClick={openModalTwelve}>
                                <img src={IndustryEventsThumbnail} />    
                                <h3> Industry Events</h3>
                            </div>
                            <div onClick={openModalTwenty}>
                                <img src={autoCategories} />    
                                <h3> Category Reports</h3>
                            </div>
                            <div  onClick={openModalNineteen}>
                                <img src={ArticleThumbnail} />    
                            <h3> Articles</h3>
                             
                            </div>
                        </div>
                    <Link to="/infographics" className="headerLinkWrapper"><h2>INFOGRAPHICS</h2></Link>
                </div>
                <div className="membersZoneWrapper">
                    <h2>SOFTWARE RESOURCES</h2>
                    <iframe width="700" height="394" style={{marginBottom: '2.5rem'}} src="https://www.youtube.com/embed/yAX-gNQ4MbE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                     <h3 onClick={openModalFour}>
                        COMMB Data Report
                    </h3>
                     <Modal
                        isOpen={modalIsOpenFour}
                        onRequestClose={closeModalFour}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>
                            COMMB Software Information
                        </h2>
                        <h3 onClick={openModalSix}><a onClick={openModalSix}>How to install COMMB DataSuite</a></h3>
                        <h3>How to Use the Software:</h3>
                        <ul>
                            <li><a href="https://youtu.be/_hPbAGgKeT8">Training Video</a></li>
                            <li onClick={openModalFourteen}><a onClick={openModalEighteen}>Video Tutorials</a></li>
                        </ul>
                        <h3>Resource Documents</h3>
                        <ul>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/jrmHRVgyxoU5e0ryQji9T/db52b29a356e7f3e8f0dcc8559812e6f/Face_Count_Tracker_Report.xls" target="_blank">Face Count Tracker Report</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/1JKvqvD2FJlVWQIOmq4Xmw/63456337a19a71dd37a2199bc9d71581/COMMB_National_OOH_Inventory_Overview_20220101.pdf" target="_blank">National Out-of-Home Inventory Overview</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/1DeB0m6Ad1WQ9cPsK8advU/e739a4d149e3677dd21a9780cec1ef11/COMMB-Data-Report-Definitions-1.pdf" target="_blank">Definitions</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/5y5w0g6uN7gpJlf5FNqWdE/77f7a35df25a1b416ac28163568a45a6/COMMB_Reference_Data_20220101.xls" target="_blank">COMMB Reference Data</a></li>
                            <ul>
                                <li>Load Factor and InMarket %</li>
                                <li>Pedestrian Counts</li>
                                <li>Place-Based Update Schedule</li>
                                <li>Outdoor Update Schedule</li>
                                <li>Population Estimates</li>
                                <li>Operator – New Products</li>
                            </ul>
                        </ul>
                        <h3><a href="https://commb.wpengine.com/cdr/">About COMMB Data Report: Here</a></h3>
                        <h3>Support Inquiries</h3>
                        <ul>
                            <li>Technical & General: <a href="mailto:support@commb.ca">support@commb.ca</a></li>
                            <li>Installation & Access: <a href="mailto:install@commb.wpengine.com">install@commb.wpengine.com</a></li>
                        </ul>
                        <h3>Training</h3>
                        <p>Custom Training: <a href="mailto:jstick@commb.wpengine.com">Email Jeremy Stick</a></p>
                        <h3>PDF Versions for download</h3>
                        <p>
                            Summer 2022 (July)
                        </p>
                        <ul>
                            <li>
                                <a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5vKZeImZg9OSdapQ8lB5gA/d205cc324a97225f572b1ee2d45b6bd8/COMMB_Data_Report_-_Summer_2022.pdf">CDR</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/77xvzu5BwWLkGGl2DBNAzw/35aeff0b7cc932a306c40f3e2f3fe167/COMMB_National_OOH_Inventory_Overview_20220701.pdf">National Overview</a>
                            </li>
                        </ul>
                        <p>
                            Winter 2022 (January)
                        </p>
                        <ul>
                            <li>
                                <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Winter_2022.pdf">CDR</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20220101.pdf">National Overview</a>
                            </li>
                        </ul>
                        <p>Summer 2021 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Summer_2021.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20210701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2021 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Winter_2021.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20210101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2020 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Summer_2020.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20200701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2020 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2020/01/06093021/COMMB_Data_Report_-_Winter_2020.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2020/01/06093026/COMMB_National_OOH_Inventory_Overview_20200101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2019 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/07/04110943/COMMB_Data_Report_-_Summer_2019.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/07/04095152/COMMB_National_OOH_Inventory_Overview_20190701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2019 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/03/01092647/COMMB_Data_Report_-_Winter_20191.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/01/07091658/COMMB_National_OOH_Inventory_Overview_20190101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2018 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/28140031/COMMB_Data_Report_-_Summer_2018.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMMB_National_OOH_Inventory_Overview_20180703.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2018 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2018_20180201.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/08/16124529/COMB_National_OOH_Inventory_Overview_20180201.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2017 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2017.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMMB_National_OOH_Inventory_Overview_20170701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2017 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2017.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMB_National_OOH_Inventory_Overview_20170101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2016 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2016.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110123/COMB_National_OOH_Inventory_Overview_20160701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2016 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2016.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110247/COMB_National_OOH_Inventory_Overview_20160101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2015 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2015.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110204/COMB_National_OOH_Inventory_Overview_20150706.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2015 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2015.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110346/COMB_National_OOH_Inventory_Overview_20150101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2014 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2014.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https:/commb.wpengine.com/wp-content/uploads/COMB-National-OOH-Inventory-Overview-S14.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2014 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2014.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110515/COMB-National-OOH-Inventory-Overview-W14.pdf">National Overview</a></li>
                            </ul>
                        <p>Spring 2013</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Spring_2013.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110437/COMB_National_OOH_Inventory_Overview.pdf">National Overview</a>    </li>
                            </ul>
                        <a className="closeBtnModal" onClick={closeModalFour}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                    <ul>
                            <li><a target="_blank" href="https://youtu.be/_hPbAGgKeT8">Training Video</a></li>
                        <li><a onClick={openModalEighteen}>Video Tutorials</a></li>
                        <Modal
                                isOpen={modalIsOpenEighteen}
                                onRequestClose={closeModalEighteen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>COMMB Data Report</h2>
                                <p>These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                                <hr />
                                <br />
                                <h3>Video 1</h3>
                                <p>Understanding the basics</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Xr_IESWeLPA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 2</h3>
                                <p>How to build the default report for Product Groups or Networks by Market</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/lkPK38hWa48" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 3</h3>
                                <p>How to build a major market report with CSD details broken out</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/fUDH0QweCLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 4</h3>
                                <p>How to determine changes in inventory vs the previous wave period</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/bHgJzBTuu2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 5</h3>
                                <p>How to create a report without subtotals (table mode)</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/vsrkF_bXbfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 6</h3>
                                <p>How to group Outdoor digital and static products separately in a report</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/yUDTJwEuwqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 7</h3>
                                <p>How to run a report for a custom outdoor company market</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/jmRi93jbTic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 8</h3>
                                <p>How to run a Pattison market report with proximate CSDs</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/sxdGny9F_O0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <a className="closeBtnModal" onClick={closeModalEighteen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                    </ul>
                    <h3 onClick={openModalFive}>Navigator R/F</h3>
                     <Modal
                        isOpen={modalIsOpenFive}
                        onRequestClose={closeModalFive}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>
                            Navigator R/F
                        </h2>
                        <h3><a onClick={openModalFive}>Installing COMMB DataSuite</a></h3>
                        <h3>How to Use the Software:</h3>
                        <ul>
                            <li><a target="_blank" href="https://www.youtube.com/watch?v=7pbWQpaW72U">Training Video</a></li>
                            <li>Tutorials (segmented into bite-sized topics)</li>
                            <Modal
                                isOpen={modalIsOpenThirteen}
                                onRequestClose={closeModalThirteen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>COMMBNavigator®</h2>
                                <p>These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                                <hr />
                                <br />
                                <h3>Video 1</h3>
                                <p>Understanding the basics; how to get around</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/j53xS-WJQHc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 2</h3>
                                <p>Using the Plan Builder to construct simple plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/PucnA7w9qFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 3</h3>
                                <p>Using the Plan Builder to compare multi-scenario plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cWWss2x5FyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 4</h3>
                                <p>How to select a basic target & create a combined target</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8T_kV2ygi8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 5</h3>
                                <p>How to build and save your own report layouts</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 6</h3>
                                <p>How to review and finalize report details</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 7</h3>
                                <p>Saving options</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 8</h3>
                                <p>How to use the Flighting option</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2tcwsDNbt6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 9</h3>
                                <p>How to build graphs</p>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rEjcqs7KQ_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <a className="closeBtnModal" onClick={closeModalThirteen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                            <ul>
                                <li><a onClick={openModalThirteen}>Video</a></li>
                                <li><a onClick={openModalFifteen}>Print</a></li>
                            </ul>
                            <li><a>Quick Tips Series (learn how to get the most out of the software)</a></li>
                            <li><a onClick={openModalSeventeen}>FAQ</a></li>
                            <Modal
                                isOpen={modalIsOpenSeventeen}
                                onRequestClose={closeModalSeventeen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>FAQ - COMMBNavigator®</h2>
                                <h3>1. How can I access the COMMBNavigator® software?</h3>
                                <p>Once you become a COMMB member you can obtain your username/password by filling out the online request form. Please contact your IT department to handle the installation of the software since administrative permissions are required for this.</p>
                                <hr />
                                 <br />
                                <h3>2. How can I understand some of the terms/data variables used in COMMBNavigator®?</h3>
                                <p>Once you are logged into the COMMBNavigator® you can access the “Definitions” document in the Help menu.</p>
                                <hr />
                                 <br />
                                <h3>3. How often is COMMBNavigator® updated?</h3>
                                <p>Twice a year (January and July). An off-schedule update is sometimes requested by an OOH company member when a significant inventory change has occurred that they would like to see reflected immediately.</p>
                                <hr />
                                 <br />
                                <h3>4. Why are some of COMMB’s Outdoor members not included in COMMBNavigator®?</h3>
                                <p>R/F estimates are determined through the amalgamation of OOH inventory data and Numeris-RTS target data. As a result, COMMB Outdoor members are required to have a Numeris membership and be a subscriber to the NLogic-RTS Consumer Study to be reported in COMMBNavigator®.</p>
                                <p>COMMB’s Agency/Advertiser members must also be members of Numeris to gain access to the software.</p>
                                <hr />
                                 <br />
                                <h3>5. Are Outdoor digital products reported in COMMBNavigator®?</h3>
                                <p>Yes they are. Please note that current reach/frequency calculations use circulations that are based on per face, not per advertising spot.</p>
                                <hr />
                                 <br />
                                <h3>6. How many markets does COMMBNavigator® report on?</h3>
                                <p>45 including Toronto, Montreal, Vancouver, Calgary, Edmonton, Ottawa, London, Quebec City, Halifax, Winnipeg and Regina.</p>
                                <hr />
                                 <br />
                                <h3>7. Is COMMBNavigator® included in COMMB’s membership fees?</h3>
                                <p>Yes, there are no extra charges for access to COMMBNavigator®unless you are not a member of Numeris/RTS.</p>
                                <hr />
                                 <br />
                                <h3>8. Are there any license fees for COMMBNavigator®or the COMMB Data Report software?</h3>
                                <p>No. There are no fees to obtain multiple licenses for our current software so all users in the same company can have their own accounts at no extra charge.</p>
                                <hr />
                                 <br />
                                <h3>9. Why is there no rate card in COMMBNavigator®?</h3>
                                <p>Significant variances can occur with rates based on a number of industry factors that take place over a year so we do not want to misinform our clients by using a rate card. Please contact the relevant OOH company to obtain costs.</p>
                                <hr />
                                 <br />
                                <h3>10. Can I use COMMBNavigator® (or the COMMB Data Report) on my MAC?</h3>
                                <p>The software is not MAC-compatible however, there are a couple of work-around options you can discuss with your IT department:</p>
                                <p>Apple provides Boot Camp, a means of installing Windows on Macs in dual-boot fashion.</p>
                                <p>The more seamless route is to use virtual machine software, like Parallels, which effectively runs Windows (or a single Windows application) within OS X.</p>
                                <hr />
                                 <br />
                                <h3>11. Why are Transit shelters reported under the Street Furniture product and not broken out?</h3>
                                <p>Products in COMMBNavigator® are grouped together if they are the same size so, for example, all products under Street Furniture (e.g. Transit Shelters, Street Ads, Mediacolumns, Street Posters) that are 6’x 4’ are grouped together under one product name.</p>
                                <a className="closeBtnModal" onClick={closeModalSeventeen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                        </ul>
                        <h3>Resource Documents</h3>
                        <ul>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5vKOqENTHLFHhFQ4TNadQM/43b7651fc5db6ffd7dddce4225dfeb90/COMMBNavigator_Definitions.pdf">Definitions</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/468TEr45YOV6FO4Vpap1Kz/a4199cb1bb6cccbe207dd1a5c6c3dd62/Technical_Details_COMMB_Navigator.pdf">COMMBNavigator® Technical Details</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/72Ow4Ng9q5ztdDUVjXV7vz/bc99c71c9e3a10abf04be17d375c4abb/RTS_Technical_Details_Spring_2022.pdf">RTS Technical Details - Spring 2022</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/6idleIEoWTUV5iaRGryZxC/494e15714da4461207acaa1b6bc3a33f/2022_Spring_RTS_Codebook_English.pdf">RTS Codebook - Spring 2022</a></li>
                        </ul>
                        <h3>Support Inquiries</h3>
                        <ul>
                            <li>Technical & General: <a target="_blank" href="mailto:support@commb.ca">support@commb.ca</a></li>
                            <li>Installation & Access: <a target="_blank" href="mailto:install@commb.wpengine.com">install@commb.wpengine.com</a></li>
                        </ul>
                        <h3>Training</h3>
                        <p>Demonstration Webinar: <a target="_blank" href="https://commb.wpengine.com/mz-commb-school/">COMMB School Registration</a></p>
                        <p>Custom Training: <a target="_blank" href="mailto:jstick@commb.wpengine.com">Email Jeremy Stick</a></p>
                        <a className="closeBtnModal" onClick={closeModalFive}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                        <ul>
                            <li><a target="_blank" href="https://www.youtube.com/watch?v=7pbWQpaW72U">Training Video</a></li>
                            <li><a onClick={openModalFourteen}>Video Tutorials</a></li>
                            <Modal
                                isOpen={modalIsOpenFourteen}
                                onRequestClose={closeModalFourteen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>COMMBNavigator®</h2>
                                <p>These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                                <hr />
                                <br />
                                <h3>Video 1</h3>
                                <p>Understanding the basics; how to get around</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/j53xS-WJQHc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 2</h3>
                                <p>Using the Plan Builder to construct simple plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/PucnA7w9qFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 3</h3>
                                <p>Using the Plan Builder to compare multi-scenario plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cWWss2x5FyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 4</h3>
                                <p>How to select a basic target & create a combined target</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8T_kV2ygi8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 5</h3>
                                <p>How to build and save your own report layouts</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 6</h3>
                                <p>How to review and finalize report details</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 7</h3>
                                <p>Saving options</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 8</h3>
                                <p>How to use the Flighting option</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2tcwsDNbt6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 9</h3>
                                <p>How to build graphs</p>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rEjcqs7KQ_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <a className="closeBtnModal" onClick={closeModalFourteen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                            <li><a onClick={openModalFifteen}>Print Tutorials</a></li>
                            <Modal
                            isOpen={modalIsOpenFifteen}
                            onRequestClose={closeModalFifteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                            <h2>Print Tutorials</h2>   
                            <h3>1. Choosing Inventory</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4LsiurWZ8uFm84CQf5m6fr/6a90e0da925254e2e1d558f2faed7823/um1_1.pdf">1.1 Finding which OOH Companies have a particular product within one or more markets</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7zaqNhMfvE2oV2dbNXxpNU/cc3b1d8098e4a6a296a0ae8b039410db/um1_2.pdf">1.2 Determining inventory to use in a plan</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2oWmPA7QlbPRAep0NLtdqv/67ffffc379f84388df4822fee9b62845/um1_3.pdf">1.3 Adding inventory to a new plan</a></li>
                                
                            </ul>
                            <h3>2. Defining Plans</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5BJuRLTzkTZZNoFUWBUwfr/107c9f9c029e5966f40f7c6809f9f8d6/um2_1.pdf">2.1 Assigning the same single value to the number of weeks column for all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3DMwhCKxYaRKh4r1vnsrXf/a85e3b690408c4911eef16db43fa8fe8/um2_2.pdf">2.2 Assigning the same costing to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/6jHPcUbJdTYWrr8EyQXjXb/47d2844c63090c86f3d0efc65ac1eb1b/um2_3.pdf">2.3 Assigning the same objective to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1G9QtOfFhoiPKsKho5yV4t/92d3a217c8a6bdf3a225415ddd752a7f/um2_4.pdf">2.4 Defining a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/SAwFRTnKbPwJmcaqpFnds/e9e69b48174ea10f01bd45877e9abb03/um2_5.pdf">2.5 Defining a plan with different campaign lengths for some products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5EXyED4ITCM3umQZ8UqAJI/fe014e208540a15cac2dcc119c281989/um2_6.pdf">2.6 Defining a plan to compare different objective levels for each product</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2IoEi0YOLqBQHI2raJkV4T/3a65c8b83c62b91123f91bcf96ac496a/um2_7.pdf">2.7 Applying costs to a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2dsp9gJ9j8AJVfWQu2O2H4/e94f0caf97ee8125336ffbd0833d0154/um2_8.pdf">2.8 Overriding a circulation</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7szCC2r8PkwkHJfIPrSWkQ/053fa5e8436f51e7233b680e48b612bb/um2_9.pdf">2.9 Defining multiple plans to compare products</a></li>
                            </ul>   
                            <h3>3. Flighting</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3QsYTc0uouotbzsJ7RlcgQ/97137088e68f06f9ea17312eaa14c92d/um3_1.pdf">3.1 Creating a Flighting schedule for all selected inventory</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1IJiMjLLA31Y2AQgGmdtBs/8c4d12981863855714dc5511a5c3607e/um3_2.pdf">3.2 Creating different Flighting schedules that vary by product</a></li>
                            </ul>
                            <h3>4. Targets</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2SF6c6xYpLob1teL8jUI11/860e5fc6478d21b0f80b9c352af38a94/um4_1.pdf">4.1 Creating a new combined target</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3yeyWriGYn55qwMWOlp54o/62dc748aa52de8b305f8c66c023fa56e/um4_2.pdf">4.2 Adding a single target</a></li>
                            </ul>
                            <h3>5. Report Appearance</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/sJskb0nmp2vqSu5C9ALcT/a9732b254ccab71e10add4ce80dee5a4/um5_1.pdf">5.1 Enter or change the report title and footer</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5XVVG3eHDzh1AFm9YFzXjD/f3e7bf83d9b0c020ead59be4f20037bd/um5_2.pdf">5.2 Changing preferences</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4T8tnRdBE6I4IpcA2wHCGS/667ab62fa4400e97e28cf4e8b7394ead/um5_3.pdf">5.3 Adding a logo to reports</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2l8xCKni2t4SIC2hsGzvKb/3529f5caf7e6194c327bc36dc06e41a1/um5_4.pdf">5.4 Altering the columns of a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1WeVwAcN2F7lTlwuX7dkVW/f41f08e168429f9990da899d86816441/um5_5.pdf">5.5 Changing the report group order for a single-level or mutli-level report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4mRObMKzv5RCHfy3bC8Az3/08a9142c606b458555504387753cdff3/um5_6.pdf">5.6 Changing the order of markets in a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5S8txUFBr7STOtseeeUhQP/dcd4a0f1b0119048438689a3cdfeb404/um5_7.pdf">5.7 Removing mixes from a report</a></li>
                            </ul>
                            <h3>6. Managing Files</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58Pjncd5t85Be0u0Dc8sLV/4ada7304f3255b8348013ad83a34ad03/um6_1.pdf">6.1 Saving data selections</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4W431f6LEyJL2MvTyRrHUA/011f64136952177fe75c7cf65c023da3/um6_2.pdf">6.2 Opening files</a></li>
                            </ul>
                            <h3>7. Viewing and Exporting Reports</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7G14cTtYfzgJEC9lOYWLhk/d7a958f9f43827dc970d7b7db80e125c/um7_1.pdf">7.1 Completing the report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3FqLvgDMRSBgN1IqbWvQKs/ddcb904206722b4f632bf98c2770ec2c/um7_2.pdf">7.2 Recalculating results</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/ru9WDVCTY2ibaso6SWWtg/9befeac5c68a4e0191de924504bad0af/um7_3.pdf">7.3 Saving another copy of the report under a different filename in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1nVtBGmFGFWl0LueV7iy1V/3e65355fda1c423f6724b2728d59d8cf/um7_4.pdf">7.4 Saving another copy of the report under a different filename in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58blpQ176UJThxRZ4tcBgg/4644e65cd10b26f239c0fca583d7f67d/um7_5.pdf">7.5 Saving a copy of the report to PDF format in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4WSZEwm7z3OcQT5NMqteVe/2998d3d712af05b0f5543b1625cde50a/um7_6.pdf">7.6 Saving a copy of the report to PDF format in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1xu2UdUNhyTUQ2rjlYXkyY/4cf528de36b1476b78447eb324548482/um7_7.pdf">7.7 Sending a PDF copy of the report via email in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2Oko70qTEGSWcG7QIkY0Kx/ca0edcbf39e9c087c15439877f13d370/um7_8.pdf">7.8 Sending a PDF copy of the report via email in Excel 2013</a></li>
                            </ul>
                            <h3>8. Graphing</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/zES9upqsJfOByXtJXy1GM/4f6692aeef2cfe6ddc23b5b88603bf78/um8_1.pdf">8.1 Comparing Reach Build</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3XOfnCTzns805Cn3AJQlWf/c4b5250f0f505d82b3c9a01322c045a2/um8_2.pdf">8.2 Graphing Effective Reach</a></li>
                            </ul>
                            <a className="closeBtnModal" onClick={closeModalFifteen}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>
                            </Modal>
                            <li><a onClick={openModalSixteen}>Quick Tips Series</a></li>
                            <Modal
                            isOpen={modalIsOpenSixteen}
                            onRequestClose={closeModalSixteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Quick Tips Series</h2>
                            <ul>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/1fBvJ6C9RtzuRydNpPeH8k/336287df60533b2a90e2afac894f2e0f/Quick_Tip_1_Become_an_Expert_Navigator.pdf" target="_blank">Quick Tip #1 - Become an Expert Navigator</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/72VVHRfeOxaLCQbwJWdgll/5f878f58a7ebe2ebe693714d8fee5a91/Quick_Tip_2_Plan_smarter._Faster..pdf" target="_blank">Quick Tip #2 - Plan Smarter. Faster.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/22YcNGUs3dD8DSV7ie5DmN/10004395b90db9351f7d9fef7b2dbf84/Quick_Tip_3_Simple_steps_for_planning_smarter.pdf" target="_blank">Quick Tip #3 - Simple steps for planning smarter</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/4lel6EhnwUGvvWfNDA3gXY/921c09d7c2ce09951605cbd19da87bf3/Quick_Tip_4_A_step_in_the_right_direction.pdf" target="_blank">Quick Tip #4 - A step in the right direction</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/37omXPtNtpkSaBYUNp1WOS/630261d392232eca27bebc5a923eff4e/Quick_Tip_5_Getting_the_Most_out_of_Plan_Builder.pdf" target="_blank">Quick Tip #5 - Getting the most out of Plan Builder</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/BtkdEE0S6vuSqNryTLSPN/47403806b16b39b9bec14c8e2f71ace0/Quick_Tip_6_Your_Reports._Your_Way..pdf" target="_blank">Quick Tip #6 - Your Reports. Your Way.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/6XdfAzs13XbTM9WwEIu8pU/c53b3437d5439598d6b1b8edd572557a/Quick_Tip_7_Flighting_Made_Easy.pdf" target="_blank">Quick Tip #7 - Flighting Made Easy</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/yymTMmLgve2hXrZBnt7ps/a51c86a17bbeae4ad92fdffb4cc58bad/Quick_Tip_8_Graph_Your_Results.pdf" target="_blank">Quick Tip #8 - Graph Your Results</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/35eL1dbrzvVA52s0WjKRLM/2b56a3ac408d24b7ebbfc6b57b8f3b2b/Quick_Tip_9_Save_It._Your_Way..pdf" target="_blank">Quick Tip #9 - Save It. Your Way.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/69vZZlTV6WJpkQRCkRAb4N/6c9dea094bcac091d38902b316613b58/Quick_Tip_12_Sharing_a_Plan_with_a_colleague.pdf" target="_blank">Quick Tip #10 - Circulation Overwrite Feature</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/4AhG4NLUWtGApgaHOKnW7r/c79ba11dd128d69153d09e1d63dcb175/Quick_Tip_11_Navigator_Change_Wave_Feature.pdf" target="_blank">Quick Tip #11 - Change Wave Feature</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/69vZZlTV6WJpkQRCkRAb4N/6c9dea094bcac091d38902b316613b58/Quick_Tip_12_Sharing_a_Plan_with_a_colleague.pdf" target="_blank">Quick Tip #12 - Sharing a Plan With a Colleague</a></li>
                            </ul>
                        </Modal>
                        
                        </ul>
                        <h3 onClick={openModalSix}>Installing COMMB Data Suite</h3>
                         <Modal
                            isOpen={modalIsOpenSix}
                            onRequestClose={closeModalSix}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Installing COMMB Data Suite</h2>
                            <p>We recommend installation is handled by your IT department since users require administrator permissions. Details on how to install the software are indicated under Tutorials below.</p>
                            <img src={DownloadImg} />
                            <p>
                                <a target="_blank" href="http://www.rja.net/comb/COMMBDataSuiteSetup.msi">COMMBDataSuiteSetup.msi (9 MB) </a>
                            </p>
                            <p>
                                (COMMBDataSuiteSetup.msi is the installer for COMMBNavigator® and COMMB Data Report software).
                            </p>
                            <h4>Tutorials</h4>
                            <ul>
                                <li><a onClick={openModalSeven}>How to install COMMNavigator® or COMMB Data Report</a></li>
                                <li><a onClick={openModalEight}>How to update COMMBNavigator® or COMMB Data Report</a></li>
                            </ul>
                            <h4>Other</h4>
                            <ul>
                                <li><a target="_blank" href="#">Deployment Notes for Network Administrators</a></li>
                                <li>Don’t have administrative privileges or an IT department?  Use <a target="_blank" href="http://www.rja.net/comb/COMBDataSuiteSetup.exe">COMMBDataSuiteSetup.exe instead.</a></li>
                            </ul>
                            <a className="closeBtnModal" onClick={closeModalSix}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>
                        </Modal>
                        <ul>
                            <li><a onClick={openModalSeven}> How to install</a></li>
                            <Modal
                                isOpen={modalIsOpenSeven}
                                onRequestClose={closeModalSeven}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>How to install</h2>
                                <p>This tutorial details how to download, install and run COMMB DataSuite (installer for COMMBNavigator® and COMMB Data Report software).</p>
                                <h4>A Note For Network Administrators</h4>
                                <p>For Group Policy installations, see <a href="http://commb.wpengine.com/mz-installing-commb-data-suite/deployment-notes-network-administrators/">Notes for Network Deployment</a> of COMMBNavigator® & COMMB Data Report.</p>
                                <h4>Download COMBDataSuiteSetup</h4>
                                <img src={setupImg1} />
                                <ul>
                                    <li>1. Click here to download COMBDataSuiteSetup.msi.</li>
                                    <li>2. Depending upon your browser configuration, you will be asked to either (A) Open or Run the file, or (B) Save the file to your PC.</li>
                                    <li>3. If you save COMBDataSuiteSetup to your PC, remember the folder where you saved it. Saving to your desktop or Downloads folder is recommended, since they are easy to find. Navigate to that folder and run COMBDataSuiteSetup by double-clicking it.</li>
                                </ul>
                                <p>Note: If you have an antivirus product like Norton Antivirus, it may ask you whether you trust the program or this website. Answer Yes or OK to continue.</p>
                                <hr />
                                <p>In Windows XP SP2 and later, Windows Vista and Windows 7, the operating system will usually ask you to confirm if you want to let COMBDataSuiteSetup run, by presenting one of the dialogs above.</p>
                                <p>Please note that the publisher listed above, Richard Jean & Associates Inc. is the developer of COMMBNavigator® and COMMB Data Report.</p>
                                <p>Note:  Installing COMBDataSuiteSetup.msi requires administrator privileges. If your Windows account does not have administrator privileges, enter your Windows password if requested, and click Yes to continue.</p>
                                <p>If your Windows account does have administrator privileges, you will see the dialog on the left, or something similar. To continue, your network administrator will need to enter their password for installation to continue. If this is not possible, download and install using COMBDataSuiteSetup.exe instead, which does not require administrative privileges.</p>
                                <hr />
                                <h4>Welcome to COMMBNavigator® Setup</h4>
                                <img src={setupImg2} />
                                <p>The Installation Wizard will appear. Follow the instructions by closing other programs.  Click Next.</p>
                                <hr />
                                <h4>Read and Accept the License Agreement</h4>
                                <img src={setupImg3} />
                                <p>When the license agreement step appears, read the entire license, and click the ‘I accept’ checkbox if you agree to the terms. Click Next.</p>
                                <hr />
                                <h4>Finish the Installation</h4>
                                <img src={setupImg4} />
                                <p>The installation is complete. Click Finish</p>
                                <hr />
                                <h4>Running COMMB DataSuite For the First Time – Launching COMMB DataSuite</h4>
                                <img src={setupImg5} />
                                <p>Find the COMMB shortcut in either the Start Menu or on the Desktop. Select the shortcut in the Start Menu, or double-click the shortcut on the Desktop.  This will open the COMMB DataSuite login dialogue window (seen below).</p>
                                <img src={setupImg6} />
                                <hr />
                                <h4>Note For COMMB Data Report Installers/Users:</h4>
                                <p>The first time you run COMMB DataSuite, it will automatically download, install and launch the latest version. See the tutorial on Updating COMMB DataSuite for details.</p>
                                <h4>Login to COMMBNavigator® or COMMB Data Report</h4>
                                <img src={setupImg7} />
                                <p>The login dialog appears once you are running the latest version of COMMB DataSuite.</p>
                                <p>Your user name and password can be found in the “Welcome to COMMB DataSuite Software” message sent to your email address. If you have not received this email, contact support@commb.ca  to open an account.*</p>
                                <ul>
                                    <li>1. Enter your COMMBNavigator®/COMMB Data Report User Name. This is your email address.</li>
                                    <li>2. Enter your COMMBNavigator®/COMMB Data Report Password in the password box.</li>
                                    <li>3. If you do not wish to have to remember your password, select the Store Password checkbox to have it saved for you. Do not store your password if your PC is shared by multiple people.</li>
                                    <li>4. Click OK to login to COMMBNavigator®** and/or COMMB Data Report.</li>
                                </ul>
                                <p>**Only select COMMB OOH media owner members have access to COMMBNavigator®.</p>
                                <p>COMMBNavigator®/COMMB Data Report will attempt to login to COMMB’s server. If this fails, it is likely that your Internet connection is down or your company’s network configuration does not allow you to reach COMMB. If this happens, please contact your network administrator.</p>
                                <hr />
                                <p>Note: It is important for the proper functioning of COMMBNavigator®/COMMB Data Report that you login with your own COMMBNavigator®/COMMB Data Report credentials, and do not share your credentials with others. These applications store your personal files and settings in your online account, and these files are available to anyone who knows your password.</p>
                            <p>If you have lost your password, enter your email address and click Retrieve Lost Password. A new password will be sent to the email address associated with your account. Find the new password in that email and enter it in the password field in the login dialog. </p>
                            <a className="closeBtnModal" onClick={closeModalSeven}>
                            {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>
                            </Modal>
                            <li><a onClick={openModalEight}>How to update</a></li>
                            <Modal
                                isOpen={modalIsOpenEight}
                                onRequestClose={closeModalEight}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>How to update</h2>
                                <p>This tutorial details how to update COMMB DataSuite (installer for COMMBNavigator® and COMMB Data Report software).</p>
                                <hr />
                                <h4>Launch COMMB DataSuite</h4>
                                <img src={updateImg1} />
                                <p>Updating occurs automatically every time COMMB DataSuite is launched. To launch COMMB DataSuite, find the COMMB shortcut in either the Start Menu or on the Desktop.</p>
                                <p>Select the shortcut in the Start Menu, or double-click the shortcut on the Desktop.</p>
                                <hr />
                                <h4>Automatic Version Updates</h4>
                                <img src={updateImg2} />
                                <p>Before starting, COMMB DataSuite checks to see if an update is available from COMMB. If an update is available, the above window will appear. Click OK.</p>
                                <img src={updateImg3} />
                                <hr />
                                <h4>Update Download</h4>
                                <img src={updateImg4} />
                                <p>The download progress will be indicated. After downloading is complete, COMMB DataSuite automatically installs the update.</p>
                                <hr />
                                <h4>Update Complete</h4>
                                <img src={updateImg5} /> 
                                {/* how_to_update_update_complete */}
                                <p>When the update is complete, the above window appears. Click OK. COMMB DataSuite will exit and the updated version of will automatically run and the login window will appear. Note: If the update process loops repeatedly, the update did not complete properly. If this happens, please contact Support.</p>
                                <hr />
                                <h4>Login to COMMB DataSuite</h4>
                                <img src={updateImg5} />
                                {/* how_to_install_login_to_combnavigator_ */}
                                <p>The login dialog appears once you are running the latest version of COMMB DataSuite.</p>
                                <p>Your user name and password can be found in the “Welcome to COMMB DataSuite Software” message sent to your email address. If you have not received this email, contact support@commb.ca to open an account.</p>
                                <ul>
                                    <li>1. Enter your COMMBNavigator®/COMMB Data Report User Name. This is your email address.</li>
                                    <li>2. Enter your COMMBNavigator®/COMMB Data Report Password in the password box.</li>
                                    <li>3. If you do not wish to have to remember your password, select the Store Password checkbox to have it saved for you. Do not store your password if your PC is shared by multiple people.</li>
                                    <li>4. Click OK to login to COMMBNavigator®** and/or COMMB Data Report.</li>
                                </ul>
                                <p>**Only select COMMB OOH media owner members have access to COMMBNavigator®.</p>
                                <p>COMMBNavigator®/COMMB Data Report will attempt to login to COMMB’s server. If this fails, it is likely that your Internet connection is down or your company’s network configuration does not allow you to reach COMMB. If this happens, please contact your network administrator.</p>
                                <hr />
                                <p>NOTE: It is important for the proper functioning of COMMBNavigator®/COMMB Data Report that you login with your own COMMBNavigator®/COMMB Data Report credentials, and do not share your credentials with others. These applications store your personal files and settings in your online account, and these files are available to anyone who knows your password.</p>
                                <p>If you have lost your password, enter your email address and click Retrieve Lost Password. A new password will be sent to the email address associated with your account. Find the new password in that email and enter it in the password field in the login dialog. </p>
                                <a className="closeBtnModal" onClick={closeModalEight}>
                                {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                            <li><a onClick={openModalNine}>Deployment Notes for Network Administrators</a></li>
                            <Modal
                                isOpen={modalIsOpenNine}
                                onRequestClose={closeModalNine}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>Deployment Notes for Network Administrators</h2>
                                <h4>General Information</h4>
                                <p>COMBDataSuiteSetup.msi can be used for local installation on individual PCs, or centralized deployment via group policies.</p>
                                <h4>Network Deployment using Group Policies</h4>
                                <p>COMMBNavigator®/COMMB Data Report installs into a user’s profile (C:\Users\ or C:\Documents and Settings\) rather than C:\Program Files. Therefore a Group Policies Software Installation should target user accounts (User Configuration) rather than targeting specific PCs (Computer Configuration). If your domain uses roaming profiles, the Navigator installation will roam with the user. Within the Group Policy Object Editor or Group Policy Management Console, the relevant area of interest is User Configuration => Software Settings => Software Installation.</p>
                                <p>As an MSI file, silent installation is supported via the /q command-line switch.</p>
                                <h4>Software Updates</h4>
                                <p>COMMBNavigator®/COMMB Data Report automatically checks for updates each time it runs, and installs an update from COMMB into the user’s profile when available. Software updates do not need to be pushed out by group policies.</p>
                                <p>To support COMMBNavigator®/COMMB Data Report updates for limited users without installing an elevated service, COMBNavigator®/COMMB Data Report is installed into the roaming profile of the Windows account that runs COMBDataSuiteSetup.</p>
                                <p>If COMMBNavigator®/COMMB Data Report is installed improperly (e.g. into the All Users account rather than into a user’s profile), the update process will fail for limited users, and the limited user will not be able to runCOMMBNavigator®/COMMB Data Report. This will manifest as an error during the update process: “An unexpected error occurred attempting to extract an update of COMMB DataSuite. The application will exit.”</p>
                                <h4>Local Deployment</h4>
                                <p>The same MSI file can also be used for local deployment. Local deployment using COMBDataSuiteSetup.msi requires administrative privileges, causing an elevation prompt to appear. Administrators can perform an over-the-shoulder elevation to allow installation to continue in a limited user’s account. Since COMMBNavigator®/COMMB Data Report installs into a user’s profile, local installations intended for a different user should never be performed under the administrator’s account (e.g. using RunAs), since this will result in installation in the administrator account, not the user’s account. Uninstallation does not require elevation, and can be performed by the limited user.</p>
                                <p>Unattended local installations for users without administrative rights can be performed using the EXE installer instead of the MSI Navigator installer. Silent installations can be performed with the EXE installer using the /s command-line switch.</p>
                                <h4>System Requirements</h4>
                                <p>COMMBNavigator®/COMMB Data Report uses port 80 (HTTP) for all communications with COMMB, and to obtain software updates. If this port is firewalled, COMMBNavigator®/COMMB Data Report will indicate that it cannot communicate with COMB’s servers and will not be able to start successfully. If HTTP traffic is redirected (e.g. as commonly seen by non-free public WiFi locations before logging in), COMMBNavigator®/COMMB Data Report will report that the response from COMMB is invalid, and will not be able to start successfully.</p>
                                <p>Up to 50MB of disk space, and 400MB of RAM is required during operation of the software. COMMBNavigator®/COMMB Data Report requires Windows XP SP2 and up.</p>
                                <h4>Login Requirements</h4>
                                <p>Each user requires their own login credentials, consisting of their email address and a password. These are delivered by COMMB to the user’s inbox, so COMMB will need to be aware of your users’ email addresses in advance of their use of COMMBNavigator®/COMMB Data Report. Contact COMMB’s Support to coordinate deployment with the sending of user credentials.</p>
                                <p>Administrators need to white list install@commb.ca and support@commb.ca in order for spam filters to allow emails to reach all users.</p>
                                <h4>Troubleshooting</h4>
                                <p>If you deploy over the network and are experiencing problems when users first run COMMBNavigator®/COMMB Data Report, see Unzip Update Error in the COMMB KnowledgeBase.</p>
                                <h4>FAQ</h4>
                                <p><strong>Can the software be installed on a network share?</strong><br />
                                    No. The software is small (20MB), and should be installed in each user’s account.
                                </p>
                                <p><strong>How large is the database?</strong><br />
                                    Data is not stored locally. All data is obtained from COMB as the software operates, over port 80.
                                </p>                            
                                <p><strong>Is a MacOS installer available?</strong><br />
                                    No, just a WindowsOS installer is available.
                                </p> 
                                <p><strong>Is Citrix or Terminal Server installation supported?</strong><br />
                                    A Citrix or TS installation is not supported, but may work.
                                </p> 
                                <p><strong>Are there any other options for delivering this to MacOS users?</strong><br />
                                    On Macs, VMs like Parallels may work, but are also untested and not supported.
                                </p> 
                                <p>The Windows Installer does little other than shell and uninstall integration. If Windows Installer does not work in your VM, two application files (COMBSuite.exe, COMBSuite.1.exe) are deployed which will run properly in any folder provided that the user possesses write access. Administrators may opt to copy the two files manually into a limited users’ profile, where write access always exists for a user, and add desktop and/or start menu shortcuts as desired. COMBSuite will work and be able to update itself.</p>
                            <p>If this method is attempted with VMs, remember that each user must have their own COMBSuite installation (i.e. installed in their own profile), and therefore, their own individual VM. VMs cannot be shared between users for security reasons, because passwords are saved to the profile.</p>
                                <a className="closeBtnModal" onClick={closeModalNine}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                                </a>
                            </Modal>
                        </ul>
                        
                        <hr />
                                            <h3 onClick={openModal}>
                        MEASUREMENT METHODOLOGIES
                    </h3>
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Measurement Methodologies</h2>
                            <p>All COMMB circulation measurement methodologies are designed to identify the number of people that come within viewing distance of an out-of-home advertising (OOH) face with a reliable opportunity to see that face.</p>
                            <p>Being in the general area of an outdoor face, or simply inside a particular venue that houses OOH advertising, is not enough. COMMB requires ‘proof’ that there is a reliable opportunity to see, and uses both precision and the highest standards of quantitative measurement in sourcing that proof.</p>
                            <hr />
                            <br />
                            <h3>Outdoor Advertising</h3>
                            <p>The steps to determining average daily circulation.</p>
                            <ul>
                                <li>Step 1. Source vehicular traffic volume</li>
                                <li>Step 2. Adjust traffic volume to reflect COMMB-approved visibility criteria</li>
                                <li>Step 3. Apply load factor (average number of passengers per vehicle)</li>
                                <li>Step 4. Adjust for hours of illumination</li>
                                <li>Step 5. Conduct pedestrian studies using COMMB-approved visibility criteria (distance and direction)</li>
                                <li>Step 6. Calculate average daily circulation per face</li>
                            </ul>
                            <p><a href="https://commb.wpengine.com/wp-content/uploads/2018/02/Outdoor-Advertising-methodology.pdf">More details here</a></p>
                            <br />
                            <h3>Place-Based Advertising</h3>
                            <p>The steps to determining average weekly impressions.</p>
                            <ul>
                                <li>Step 1. Conduct pedestrian count studies</li>
                                <li>Step 2. Convert pedestrian counts to number of weekly impressions per face</li>
                                <li>Step 3. Calculate average weekly impressions per face for each place-based network in each market</li>
                            </ul>
                            <p><a href="https://commb.wpengine.com/wp-content/uploads/2018/02/Indoor-Advertising-Methodology.pdf">More details here</a></p>
                            <br />
                            <h3>Audience Numbers</h3>
                            <p>Do outdoor or place-based audience numbers provided by an OOH Company reflect Industry Standards?</p>
                            <ul>
                                <li>Outdoor Circulation Checklist</li>
                                <li>Place-Based Circulation Checklist</li>
                            </ul>
                            <a className="closeBtnModal" onClick={closeModal}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>
                    </Modal>  
                    <h3 onClick={openModalTwo}>
                        GRP and Reach Frequency
                    </h3>
                    <Modal
                        isOpen={modalIsOpenTwo}
                        onRequestClose={closeModalTwo}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>GRP and Reach Frequency</h2>
                        <h3>Calculation</h3>
                        <p>When calculating outdoor GRPs and reach-frequency (R/F), buyers and sellers utilize an OOH industry standard that excludes exposures attributed to non-residents of the market/s participating in the campaign.</p>
                        <hr />
                        <h3>Definition of outdoor GRP</h3>
                        <p>
                            Total exposures to an outdoor campaign <strong>X</strong> the In-Market Factor * x 100 / Market population
                        </p>
                        <p>
                            * Definition of In-Market Factor – the percentage of people exposed to outdoor advertising that represents residents or deemed-residents of the market. A deemed resident resides within 100 kms of a CMA boundary and travels into the CMA market 4 days or more per week based on statistically reliable GPS travel pattern studies conducted by COMMB and approved by the COMMB Research Committee.
                        </p>
                        <p>Deemed-residents are currently recognized for the markets of Toronto, Montreal and Vancouver only.</p>
                        <hr />
                        <h3>Definition of Reach</h3>
                        <p>The % of the target population that has been exposed to the advertising campaign at least one.</p>
                        <hr />
                        <h3>Definition of Frequency</h3>
                        <p>The average number of times each person reached within the target group has been exposed to the advertising. GRP and R/F reporting is available through COMMB’s proprietary media planning software, COMMBNavigator®. Information on the R/F model can be found in Navigator R/F. </p>
                        <a className="closeBtnModal" onClick={closeModalTwo}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>   
                                        <h3 onClick={openModalThree}>
                        In-Market Factor
                    </h3>
                     <Modal
                        isOpen={modalIsOpenThree}
                        onRequestClose={closeModalThree}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>In-Market Factor</h2>
                        <h3>Definition</h3>
                        <p>The in-market factor represents the percentage of people exposed to outdoor advertising that are residents or deemed-residents of the market.</p>
                        <p>A deemed-resident resides within 100 kms of a CMA boundary and travels into the CMA market 4 days or more per week based on statistically reliable GPS travel pattern studies conducted by COMMB and approved by the COMMB Research Committee.</p>
                        <p>Deemed-residents are currently recognized for the markets of Toronto, Montreal, Vancouver, Calgary and Edmonton.</p>
                        <a href="https://commb.wpengine.com/wp-content/uploads/2018/02/In-Market-Factors-how-they-are-established-and-applied.pdf">In-Market Factors – how they are established and applied</a>
                        <a className="closeBtnModal" onClick={closeModalThree}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                    <Modal
                            isOpen={modalIsOpenNineteen}
                            onRequestClose={closeModalNineteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal modalEighteen`}
                            >
 <h2 className="noLink">Articles</h2>
                        <ul>
                            <li><a target="_blank" href="/HowToMakeTheMostOutOfYourOOHCampaign-AD-COMMB.pdf">How to Make The Most Out Of Your OOH Campaign</a></li>
                            <li><a target="_blank" href="/PDOOH-AMarketingTermEntrepreneursShouldKnow-AD-COMMB.pdf">PDOOH: A Marketing Term Entrepreneurs Should Know</a></li>
                            <li><a target="_blank" href="/ThePowerOfStaticOOHInTheDigitalEra-AD-COMMB.pdf">The Power Of Static OOH In The Digital Era</a></li>
                            <li><a target="_blank" href="/TheTacticalStrategyRequiredForPDOOH-AD-COMMB.pdf">The Tactical Strategy Required For PDOOH</a></li>
                            <li><a target="_blank" href="/WhyBillboardsPresentanOpportunityforMusicMarketersandMusicians-AD-COMMB.pdf">Why Billboards Present an Opportunity for Music Marketers and Musicians</a></li>
                        </ul>
                        <a className="closeBtnModal" onClick={closeModalNineteen}>
                            {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>        
                    </Modal>       
                    <Modal
                            isOpen={modalIsOpenThirteen}
                            onRequestClose={closeModalThirteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal ModalThirteen`}
                        >
                        <h2> COMMB Talks (Members-Only)</h2>
                            <div class="centerFlexDiv">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/6mgIHLR8AdM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <Link className="modalLinkBtn" to="/talks">View all COMMB Talks </Link>
                            {/* <Link>More Past COMMB Talks</Link> */}
                            <a className="closeBtnModal" onClick={closeModalThirteen}>
                            {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>        
                        </Modal>
                        <Modal
                            isOpen={modalIsOpenEleven}
                            onRequestClose={closeModalEleven}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Webinars and Research Presentations</h2>
                            <p>2020</p>
                                <ul>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/Getting-the-Most-Out-of-OOH.pdf">Getting the Most Out of OOH</a></li>
                                </ul>
                            <p>2019</p>
                                <ul>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/All-Members-Shoppers-Webinar-.pdf">Canadian Shopper Survey: OOH Media Results</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/Indoor-Operator-Shoppers-Webinar.pdf">Canadian Shopper Survey: Audience, Relevancy & Effectiveness of Indoor Networks</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/Benchmarketing-webinar-content-canada-26feb19-changes.pdf">Benchmarketing Analysis: OOH Proven Effectiveness</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/Benchmarketing-_OOH-ROI_-webinar-Live-Recording.mp4">Benchmarketing Analysis: OOH Proven Effectiveness (Live Recording)</a>         </li>
                                </ul>
                            <p>2018</p>
                                <ul>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/Standing-on-the-Shoulders-of-Giants-INTERNATIONAL.pdf">RAPPORT UK: Effectiveness of OOH presentation</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/RAPPORT-UK_-Effectiveness-of-OOH-Live-Recording.mp4">RAPPORT UK: Effectiveness of OOH presentation (Live Recording)</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/OOH-Brand-Builder-Sales-Activator.pdf">OOH! Brand Builder & Sales Activator</a></li>
                                </ul>
                            <p>2017</p>
                                <ul>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Consumer-Panel_detailed-results-Summary_Oct-2017.xlsx">Consumer Panel Research Result Summary - Nov. 2017</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/COMMB-BrandSpark-Shopper-Webinar-April-18-2017.pdf">Canadian Shopper Study</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2017/06/Optimal-Levels-of-OOH_OAAA-Final-analysis-2017_edit1.pdf">OAAA: Optimal levels of OOH, final results</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/OAAA-2017-OOH-Trends.pdf">OAAA: 2017 Out-of-Home Trends</a></li>
                                    <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Environics-2016-Census.pdf">Environics 2016 Census</a>        </li>
                                </ul>
                            <p>2016</p>
                                <ul>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/2016-TouchPoints-OOH1.pdf"> TouchPoints Study</a></li>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/COMMB-BrandSpark-Shopper-Webinar-May-2016.pdf">BrandSpark Canadian Shopper Study</a></li>
                                </ul>
                            <p>2015</p>
                                <ul>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Branspark-Canadian-Shopper-Study-April-2015-21.pdf">Canadian Shoppers Study</a></li>
                                </ul>
                            <p>2014</p>
                                <ul>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Alertness-Levels-in-OOH-The-Active-Space.pdf"> Alertness Levels in OOH</a></li>
                                </ul>
                            <p>2013</p>
                                <ul>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Effectiveness-of-Large-OOH-format-2013.pdf">Effectiveness of Large OOH format</a></li>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Engaging-and-Influencing-Canadian-Shopper-Brandspark-20132.pdf">Engaging and Influencing Canadian Shoppers</a></li>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/comScore-Mobile-Trends-Canada-2013-11.pdf">Mobile trends (comScore)</a></li>
                                </ul>
                            <p>2010</p>
                                <ul>
                                   <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Brand-Science-Presentation-for-Media_Mar-17-20101.pdf"> How different media drive sales (Brandscience)</a></li>
                                </ul>
                            <a className="closeBtnModal" onClick={closeModalEleven}>
                            {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>                   
                        </Modal>
                        <Modal
                            isOpen={modalIsOpenTwelve}
                            onRequestClose={closeModalTwelve}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                        <h2>Marketing/Events</h2>
                        <p>FEPE's Conference | Dubai | May 2019</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/1.TOM-G-DUBAI-2019-29-04-19-V4.pdf">President's Welcome Address: Tom Goddard</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2.JeanCharlesDecaux-FEPE-2019_VDEF.pdf">Jean Charles Decaux</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/3.Tim-Delaney_Dubai_OOH_keynote-V3.pdf">Tim Delaney - Keynote Speaker</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/4.Stephen_Whyte-FEPE-2019-v5.pdf">Stephen Whyte</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/5.Christof_Baron-Fepe-May-2nd-29-04-209-final-slides-hided.pdf">Christof Baron</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/6.Nancy-Fletcher-FEPE__V7.pdf">Nancy Fletcher - OAAA President</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/8.FINAL_Barry-presentationFEPE-1.pdf">Barry Cupples (Talon: Is OOH The Evidence Economy</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/9.Egart-Daktronics-FEPE-Presentation.pdf">Daktronics: The Journey of DOOH in the Middle East</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/10.Mark_Boidman-2019-FEPE-Conference-Presentation.pdf">Mark Boidman (PJ Solomon): Fast Forward: Market Update and 2019 Predictions</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/11.Stephanie_Gutnik-FEPE-Presentation.pdf">Stephanie Gutik: (Broadsign): Don't Be A Dinosaur</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/12.A.TIMED-Exterion-Media-Fepe-Presentation-2019-v2_1.pdf">Adrian Lovejoy (Exterion Media): From Victoria to Elizabeth</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/12.B.TIMED-UPDATED-FEPE-RICHARD-M-IGNITE-29-04-19_1.pdf">The Art of Outdoor</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/12.C.TIMED-Primedia-Outdoor-FEPE-2019-International-Technology-and-Innovation-Top-3-Nominee-Presentation-25-April-2019.pdf">Primedia Outdoor</a></li>
                        </ul>
                        <p>FEPE's Conference | Sorrento | June 2018</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/FEPE-Sorrento-Conference-2018-programme.pdf">Program</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/1.-Framing-Our-Future_Tom-Goddard-FEPE-President.pdf">President's Welcome Address - Tom Goddard</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/2.-Welcome-and-Country-Update_Fabrizio-du-Chene-de-Vere-IGPDecaux.pdf">Italy - Welcome and Country Update - Fabrizio du Chène de Vère</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/3.-Connecting-the-Dots_Annie-Rickard.pdf">Transformation and The Evolution of the Specialist - Annie Rickard</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/4.-Future-Factors-and-Trends-_Gemma-Milne-Science-Technology-Journalist.pdf">Future Factors and Trends - Gemma Milne</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/5.-Quantifying-Digitals-Contribution_Neil-Eddleston-JC-Decaux-David-Bassett-Lumen-Research.pdf">Quantifying Digital's Contribution - Neil Eddleston and David Bassett</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/6.-Lets-Not-Devalue-the-Mediu_Brendon-Cook-oOhmedia.pdf">Let's Not Devalue the Medium by Following an Impression - only approach - Brendon Cook</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/7.-Piccadilly-Circus-A-Landmark-Reborn_Tim-Bleakley-Ocean-Outdoor-Reece-Kurtenbach-Daktronics.pdf">Piccadilly Circus - A Landmark Is Reborn - Tim Bleakley and Reece Kurtenbach</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/8.-How-Does-the-Money-Think-_Hari-Sundaresan-British-Telecommunications.pdf">How Does the Money Think? - Hari Sundaresan</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/10.-Opportunities-for-OOH-in-China_Wang-Huanzhang-China-Advertising-Association.pdf">Opportunities and Challenges for OOH in China - Wang Huanzhang</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/14.-A-Global-Marketer-View_David-Wheldon-RBS.pdf">A Global Marketer View - David Wheldon</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/15.-Automation-the-P-Word_Eric-Newnham-Talon-Outdoor.pdf">Automation & The P World - Eric Newnham and Christian Schmalzl</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/16.20180608_dentsu_fepe_2018_sorrento.pdf">Value Beyond Frames - How Dentsu are Reinventing OOH in Japan - Seikyo Son</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/17.-Associations-Meeting-Roud-Up_Rosanne-Caron-COMMB.pdf">World Roundup 1 - Highlights from National Associations meeting - Rosanne Caron</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/18.-Data-Driven-Effectiveness-in-Transit_Marco-Orlandi-Grandi-Stazioni-Retail.pdf">Data-driven Effectiveness in Transit Turning National Monuments into data sources - Marco Orlandi</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/19.-Effectiveness-Standing-on-the-Shoulders-of-Giants_Rapport.pdf">Effectiveness - "Standing on the Shoulders of Giants" - Fabio Nobili and Ross Wilson</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/21.-Research-Meeting-Round-Up_Kym-Frank-Geopath.pdf">World Round 2 - Highlights from Global Research Heads Meeting - Kym Frank</a></li>
                        </ul>
                        <p>Tim Spencer's Conference | Toronto | Oct. 19th</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Tim-Spencer-final_Oct-19-Toronto.pdf">The Vital Role of OOH for Brands</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Tim-Spencer-Conference-Attendee-List-Oct-19-2016.pdf">Attendee List</a></li>
                        </ul>
                        <p>ACTIVEZ Conference | Montreal | Oct. 18th</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Final-Attendee-List_Activez-Oct.-18.pdf">Attendee List</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Opening-Remarks.pdf">Rosanne Caron & Claude Foisy - Opening Remarks</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Tim-Spencer.pdf">Tim Spencer, Cognitif - Exposure. The Vital Role of OOH for Brands</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Denis-Gaumondie.pdf">Denis Gaumondie, Digital Media Village - Integrate Smart, Smartly</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Emilie-Janie.pdf">Émilie McAllister-Lapierre (Sid Lee) & Janie Thériault (Loto-Québec) - Comment le Lotto 649 a ramené la chance chez les Québécois</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Robert-Levy_-ACTIVEZ-2016_FR_Final.pdf">Robert Levy, BrandSpark - L'impact de l'affichage face à la fragmentation des médias</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Sally-Dickerson.pdf">Sally Dickerson, BrandScience & Omnicom Media Group - Trends in OOH Advertising Spend & Effectiveness</a></li>
                        </ul>       
                        <p>OMAC Marketing Initiatives</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/2016-Marketing-Priorities.pdf">OMAC Marketing Plan</a></li>
                        </ul>    
                        <p>ACTIVATE Conference - Toronto</p>
                        <ul>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Michael-Trautmann-Why-OOH-never-goes-out-of-fashion.pdf">Michael Trautmann, Thjnk - Why OOH Never Goes out of Fashion</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/George-Mike.pdf">George Bascom, Anheuser-Busch InBev & George Ault, Zulu Aplha Kilo - Squeezing More Out of Outdoor</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Joanne-Paul-Presentation.pdf">Joanne Van der Burgt, Ipsos MediaCT & Paul Burkey, RealityMine - TouchPoints Canada Study</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Monique-Dolbin.pdf">Monique Dolbin, Warc Americas - Lessons in OOH From the World's Best Campaigns</a></li>
                            <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2016/04/Final-Attendee-List.xlsx">Attendee List</a></li>
                        </ul>                
                        <a className="closeBtnModal" onClick={closeModalTwelve}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                        </a>                                   
                        </Modal>        
                     <Modal
                        isOpen={modalIsOpenFour}
                        onRequestClose={closeModalFour}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>
                            COMMB Software Information
                        </h2>
                        <h3 onClick={openModalSix}><a onClick={openModalSix}>How to install COMMB DataSuite</a></h3>
                        <h3>How to Use the Software:</h3>
                        <ul>
                            <li><a href="https://youtu.be/_hPbAGgKeT8">Training Video</a></li>
                            <li onClick={openModalFourteen}><a onClick={openModalFourteen}>Video Tutorials</a></li>
                        </ul>
                        <h3>Resource Documents</h3>
                        <ul>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/jrmHRVgyxoU5e0ryQji9T/db52b29a356e7f3e8f0dcc8559812e6f/Face_Count_Tracker_Report.xls" target="_blank">Face Count Tracker Report</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/77xvzu5BwWLkGGl2DBNAzw/35aeff0b7cc932a306c40f3e2f3fe167/COMMB_National_OOH_Inventory_Overview_20220701.pdf" target="_blank">National Out-of-Home Inventory Overview</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/1DeB0m6Ad1WQ9cPsK8advU/e739a4d149e3677dd21a9780cec1ef11/COMMB-Data-Report-Definitions-1.pdf" target="_blank">Definitions</a></li>
                            <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/5y5w0g6uN7gpJlf5FNqWdE/77f7a35df25a1b416ac28163568a45a6/COMMB_Reference_Data_20220101.xls" target="_blank">COMMB Reference Data</a></li>
                            <ul>
                                <li>Load Factor and InMarket %</li>
                                <li>Pedestrian Counts</li>
                                <li>Place-Based Update Schedule</li>
                                <li>Outdoor Update Schedule</li>
                                <li>Population Estimates</li>
                                <li>Operator – New Products</li>
                            </ul>
                        </ul>
                        <h3><a href="https://commb.wpengine.com/cdr/">About COMMB Data Report: Here</a></h3>
                        <h3>Support Inquiries</h3>
                        <ul>
                            <li>Technical & General: <a href="mailto:support@commb.ca">support@commb.ca</a></li>
                            <li>Installation & Access: <a href="mailto:install@commb.wpengine.com">install@commb.wpengine.com</a></li>
                        </ul>
                        <h3>Training</h3>
                        <p>Custom Training: <a href="mailto:jstick@commb.wpengine.com">Email Jeremy Stick</a></p>
                        <h3>PDF Versions for download</h3>
                        <p>
                            Summer 2022 (July)
                        </p>
                        <ul>
                            <li>
                                <a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5vKZeImZg9OSdapQ8lB5gA/d205cc324a97225f572b1ee2d45b6bd8/COMMB_Data_Report_-_Summer_2022.pdf">CDR</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/77xvzu5BwWLkGGl2DBNAzw/35aeff0b7cc932a306c40f3e2f3fe167/COMMB_National_OOH_Inventory_Overview_20220701.pdf">National Overview</a>
                            </li>
                        </ul>
                        <p>
                            Winter 2022 (January)
                        </p>
                        <ul>
                            <li>
                                <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Winter_2022.pdf">CDR</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20220101.pdf">National Overview</a>
                            </li>
                        </ul>
                        <p>Summer 2021 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Summer_2021.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20210701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2021 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Winter_2021.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20210101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2020 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_Data_Report_-_Summer_2020.pdf">CDR</a> <br /></li>
                                <li> <a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/COMMB_National_OOH_Inventory_Overview_20200701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2020 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2020/01/06093021/COMMB_Data_Report_-_Winter_2020.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2020/01/06093026/COMMB_National_OOH_Inventory_Overview_20200101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2019 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/07/04110943/COMMB_Data_Report_-_Summer_2019.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/07/04095152/COMMB_National_OOH_Inventory_Overview_20190701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2019 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/03/01092647/COMMB_Data_Report_-_Winter_20191.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2019/01/07091658/COMMB_National_OOH_Inventory_Overview_20190101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2018 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/28140031/COMMB_Data_Report_-_Summer_2018.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMMB_National_OOH_Inventory_Overview_20180703.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2018 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2018_20180201.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/08/16124529/COMB_National_OOH_Inventory_Overview_20180201.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2017 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2017.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMMB_National_OOH_Inventory_Overview_20170701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2017 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2017.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/cds-documents/COMB_National_OOH_Inventory_Overview_20170101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2016 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2016.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110123/COMB_National_OOH_Inventory_Overview_20160701.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2016 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2016.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110247/COMB_National_OOH_Inventory_Overview_20160101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2015 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2015.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110204/COMB_National_OOH_Inventory_Overview_20150706.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2015 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2015.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110346/COMB_National_OOH_Inventory_Overview_20150101.pdf">National Overview</a></li>
                            </ul>
                        <p>Summer 2014 (July)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Summer_2014.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https:/commb.wpengine.com/wp-content/uploads/COMB-National-OOH-Inventory-Overview-S14.pdf">National Overview</a></li>
                            </ul>
                        <p>Winter 2014 (January)</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Winter_2014.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110515/COMB-National-OOH-Inventory-Overview-W14.pdf">National Overview</a></li>
                            </ul>
                        <p>Spring 2013</p>
                            <ul>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/03/COMMB_Data_Report_-_Spring_2013.pdf">CDR</a> <br /></li>
                                <li><a target="_blank" href="https://commb.wpengine.com/wp-content/uploads/2018/06/29110437/COMB_National_OOH_Inventory_Overview.pdf">National Overview</a>    </li>
                            </ul>
                        <a className="closeBtnModal" onClick={closeModalFour}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                    <Modal
                        isOpen={modalIsOpenTwenty}
                        onRequestClose={closeModalTwenty}
                        bodyOpenClassName={`modalOpen categoryReportsModal BookCOMMBModal MemberZoneModal`}>
                        <h2>Category Reports</h2>
                        {/* <hr className="centerMe"/> */}
                        <h3 onClick={categoryReportsRedirect}>Automotive</h3>
                        <h3 onClick={categoryReportsRedirectTwo}>Travel</h3>
                        <h4 className="infoLink"><Link to="infographics">View Infographics</Link></h4>
                        <a className="closeBtnModal" onClick={closeModalTwenty}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                </div>
            </Layout>
        </div>
    )
}

export default MembersZone
